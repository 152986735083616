/* eslint-disable max-lines-per-function */
import {
  Box, Button, Flex, Headline, Text,
} from '@lce/slice_v2';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from '@lce/i18n';
import { useFeature } from '@growthbook/growthbook-react';
import _map from 'lodash/map';

import { FundraiserOptionIcon } from './FundraiserOptionIcon';
import CaesarManChecklist from './assets/Traditional-Caesar.png';
import LaptopCaesarMan from './assets/Digital-Caesar.png';
import RestaurantCaesarMan from './assets/Restaurant-Caesar.png';
import { FundraiserComparison } from './FundraiserComparison';

import { FundraiserType } from 'features/fundraiser';
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from 'ui/common';

const ProgramIconMap = {
  BR: CaesarManChecklist,
  DG: LaptopCaesarMan,
  MD: RestaurantCaesarMan,
};

type ChooseYourFundariserProgram = {
  iconSrc: string;
  title: string;
  subTitle: string;
}

const FundraiserOptionsBanner: React.FC = () => {
  const [ fundraiserComparision, setFundraiserComparision ] = useState<boolean>(false);
  const [ t ] = useTranslation();

  const isBrochureProgramEnabled = useFeature('fr_brochure_program_enabled').on;

  const fundraiserTypes: FundraiserType[] = [
    FundraiserType.Brochure,
    FundraiserType.Digital,
    FundraiserType.MealDeal,
  ];

  return (
    <Flex
      as="section"
      data-testid="fundraiser-options-banner"
      sx={ {
        alignItems: 'center',
        backgroundColor: '#f7f2ec',
        flexDirection: 'column',
        gap: [ '32px', '32px', '64px' ],
        justifyContent: 'center',
        p: [
          `calc(${ MOBILE_HEADER_HEIGHT } + 32px) 16px 32px`,
          `calc(${ MOBILE_HEADER_HEIGHT } + 64px) 16px 64px`,
          `calc(${ MOBILE_HEADER_HEIGHT } + 64px) 16px 64px`,
          `calc(${ DESKTOP_HEADER_HEIGHT } + 64px) 16px 64px`,
        ],
      } }
    >
      <Headline as="h1" variant="headingUpper">
        {t('startAFundraiser.fundraiserOptionsBanner.Title')}
      </Headline>
      <Box
        sx={ {
          display: 'grid',
          gap: [ '16px', '32px' ],
          gridTemplateColumns: [ 'auto', '1fr 1fr 1fr' ],
          gridTemplateRows: [ '1fr 1fr 1fr', 'auto' ],
          maxWidth: [ '500px', '500px', '800px' ],
        } }
      >
        {_map(fundraiserTypes, (type) => {
          const { iconSrc, subTitle, title }: ChooseYourFundariserProgram =
            t('startAFundraiser.fundraiserOptionsBanner.program', { context: type, returnObjects: true });
          const isBrochureAndBrochureProgramEnabled = type === FundraiserType.Brochure && isBrochureProgramEnabled;
          const context = `${ type }${ isBrochureAndBrochureProgramEnabled ? '-brochureProgramEnabled' : '' }`;
          const url = t('startAFundraiser.fundraiserOptionsBanner.fundraiserCreationUrl', { context });
          return (
            <FundraiserOptionIcon image={ ProgramIconMap[iconSrc] } subtitle={ subTitle } title={ title } url={ url } />
          );
        })}
      </Box>

      <Text variant="fundraiserComparison.inLineLink">
        {t('startAFundraiser.fundraiserOptionsBanner.needHelpText')}
        <Button
          aria-label="review-the-differences"
          data-testid="review-difference-link"
          onClick={ () => setFundraiserComparision(!fundraiserComparision) }
          sx={ { m: 2 } }
          variant="primaryTextLink"
        >
          {t('startAFundraiser.fundraiserOptionsBanner.fundraiserDifferencesLink')}
        </Button>
      </Text>

      {fundraiserComparision && <FundraiserComparison />}
    </Flex>
  );
};
export default FundraiserOptionsBanner;
