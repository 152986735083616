import React from 'react';
import { Flex, Text } from '@lce/slice_v2';

import { RouterLink } from 'ui/common';

export interface ILinkItemProps {
  onClick?: () => void;
  path: string;
  text: string;
}

const LinkItem: React.FC<ILinkItemProps> = ({
  onClick, path, text, ...props
}) => (
  <RouterLink
    asNav={ true }
    id={ `click-${ text.replace(' ', '-').toLowerCase() }` }
    onClick={ onClick }
    sx={ { height: 'auto' } }
    to={ path }
    variant="links.dashboardNavLink"
  >
    <Flex sx={ { alignItems: 'center', flexDirection: 'row' } } { ...props }>
      <Text ml="15px">
        {text}
      </Text>
    </Flex>
  </RouterLink>
);

export default LinkItem;
