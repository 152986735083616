/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Box, Container, Form, Heading, Spinner, Text,
} from '@lce/slice_v2';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';
import { useOktaAuth } from '@okta/okta-react';
import { Search } from 'react-feather';

import { useUserInfo } from 'features/common';
import {
  FundraiserRelationship, useIsUserElevatedRelationship, useFetchSellerByFundraiserId,
  FundraiserType,
} from 'features/fundraiser';
import { useFetchFundraiserFormData } from 'features/fundraiser/hooks/useFetchFundraiser';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { sellersFilter } from 'features/fundraiser/utils/GenerateFilteredSellersUtil';
import { SellerStatus } from 'ui/fundraiser';
import { RouterLink } from 'ui/common';
import { useStatefulFetchFundraiserById } from 'ui/hooks';

interface MatchParams {
  fundraiserId: string;
}

export type ManageSellersPageProps = RouteComponentProps<MatchParams>

const ManageSellersPage: React.FC<ManageSellersPageProps> = ({ match }) => {
  const [ t ] = useTranslation();
  const { authState } = useOktaAuth();
  const { data: user } = useUserInfo(authState.isAuthenticated);
  const { data: fundraiser, isLoading: isFundraiserLoading } = useStatefulFetchFundraiserById(match.params.fundraiserId);
  const isBrochureFundraiser = fundraiser?.FundraiserType === FundraiserType.Brochure;
  const { data: sellers } = useFetchSellerByFundraiserId(match.params.fundraiserId);
  const [ fundraiserId ] = useState(parseInt(match.params.fundraiserId));
  const { data: formData, isLoading: isFormLoading } = useFetchFundraiserFormData(fundraiserId);
  const [ filteredSellers, setFilteredSellers ] = useState(sellers || []);

  useEffect(() => {
    setFilteredSellers(sellers || []);
  }, [ sellers ]);
  const isChairpersonRole = useIsUserElevatedRelationship(fundraiser);

  if (isFormLoading || !formData?.Info || !formData?.Chairperson) {
    return (
      <Container sx={ { textAlign: 'center' } }>
        <Spinner data-testid="spinner" variant="lce" />
      </Container>
    );
  }

  if (!isFundraiserLoading && !isChairpersonRole) {
    return <Redirect to="/dashboard/fundraisers" />;
  }

  return (
    <Box data-testid="manage-sellers" sx={ { flex: 1, maxWidth: '855px' } }>
      <FundraiserNavigation fundId={ match.params.fundraiserId } url={ match.url } />
      {isChairpersonRole && (
        <Box
          sx={ {
            flex: 1,
            mb: [ 4, 6 ],
            width: '100%',
            position: 'relative',
          } }
        >
          <Form.Input.Text
            id="seller-search"
            name="seller-search"
            onChange={ e => sellersFilter(e.target.value, setFilteredSellers, sellers || []) }
            placeholder="Search"
            sx={ { width: [ '100%', '380px' ] } }
            variant="search"
          />
          <Box
            sx={ {
              position: 'absolute', left: '14px', top: '8px',
            } }
          >
            <Search color="#868686" size="20px" />
          </Box>
          {!isBrochureFundraiser && (
            <RouterLink
              data-testid="seller-report-link"
              sx={ { color: 'primaryOrange', mt: 3, fontSize: [ 4, '18px' ] } }
              to={ `/dashboard/fundraisers/seller-report/${ fundraiserId }` }
            >
              {t('dashboard.DownloadSellerReport')}
            </RouterLink>
          )}
        </Box>
      )}
      <Heading as="h1">
        {t('dashboard.ManageSellersPage.ActiveSellers')}
      </Heading>
      {isBrochureFundraiser && (
        <Text>
          {t('dashboard.ManageSellersPage.BrochureDisclaimer')}
        </Text>
      )}
      <Box sx={ { paddingTop: [ '24px', '12px' ] } }>
        {sellers ? (<SellerStatus isChairperson={ fundraiser?.Relationships.find(r => r === FundraiserRelationship.CHAIRPERSON) !== undefined } sellers={ filteredSellers } userId={ user?.Id } />) : (<div />)}
      </Box>
    </Box>
  );
};

export default ManageSellersPage;
