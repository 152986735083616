import React from 'react';
import { Text, Flex } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import FundraiserProgramCards from './FundraiserProgramCards';

const FundraiserPrograms: React.FC = () => {
  const [ t ] = useTranslation();

  return (
    <Flex
      data-testid="fundraiser-programs"
      sx={ {
        flexDirection: 'column',
        gap: '16px',
        maxWidth: '1280px',
        mx: 'auto',
        p: [ '32px 16px', '32px' ],
        width: '100%',
      } }
    >
      <Flex
        sx={ {
          flexDirection: 'column',
          gap: '8px',
          textAlign: 'center',
          textWrap: 'balance',
        } }
      >
        <Text variant="home.subheading">
          {t('home.fundraiserPrograms.title')}
        </Text>
        <Text variant="home.subdescription">
          {t('home.fundraiserPrograms.description')}
        </Text>
      </Flex>
      <FundraiserProgramCards />
      <Text variant="disclaimer">
        { t('common.BundleDisclaimer') }
      </Text>
    </Flex>
  );
};

export default FundraiserPrograms;
