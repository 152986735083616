/* eslint-disable max-len */
import {
  FundraiserSummary,
  Fundraiser,
  FundraiserListItem,
  FundraiserSellerValidation,
  FundraiserSearchOptions,
  FundraiserEmailPreferences,
  FundraiserProfitCheckAddress,
  FundraiserOrderSummary,
  FundraiserFinalOrder,
  FundraiserBrochure,
  ExistingCoupon,
  CouponsAppliedToFinalOrder,
  FinalOrderSummary,
  FinalOrderInfo,
} from './types/fundraiser';
import { EmailNotifications } from './types/emailNotifications';
import { Seller, UpdateSellerRequest } from './types/seller';
import { Stat } from './types/stats';
import { GenerateReportFiltersQuery } from './utils/GenerateReportFiltersQuery';
import {
  Group,
  GroupChairperson,
  GroupType,
  IReportFilterOptions,
} from './types';
import { ISalesCustomer } from './types/customer';

import api from 'api';
import { ApiResponse, PagedList } from 'features/common';

export const fetchGroups = async() => api.get<Group[]>('api/group');

export const fetchGroup = async(id: string) => api.get<Group>(`api/group/${ id }`);

export const fetchGroupTypes = async() => api.get<GroupType[]>('api/groupType');

export const fetchGroupChairperson = async() => api.get<GroupChairperson>('api/group/chairperson');

export const fetchFundraiserReport = async() => api.download<Blob>('api/report/fundraiser-summary'); // TODO: accept props or remove this and use the other one

export const createFundraiser = async formData => api.post('api/fundraiser', formData);

export const createFundraiserMealDeal = async formData => api.post('api/fundraiser/mealdeal', formData);

export const createFundraiserBrochure = async(formData: FundraiserBrochure) => api.post<ApiResponse<boolean>>('api/fundraiser/brochure', formData);

export const fetchFundraiserSellerSummary = async(fundraiserId: number) => api.get<FundraiserSummary>(`api/fundraiser/summary/${ fundraiserId }`);

export const fetchEmailNotifications = async() => api.get<EmailNotifications>('api/notifications/email');

export const fetchFundraiserSellerSummaryExcel = async(fundraiserId: number) => api.download<Blob>(`api/fundraiser/summary-excel/${ fundraiserId }`);

export const updateFundraiserFormData = async(fundraiserFormData: Fundraiser) => api.put<boolean>('api/fundraiser/formdata', fundraiserFormData);

export const fetchJoinFundraiserValidation = async(fundraiserGuid: string) => api.get<FundraiserSellerValidation>(`api/fundraiserseller/validation/${ fundraiserGuid }`);

export const createFundraiserSeller = async(fundraiserGuid: string) => api.post<boolean>(`api/fundraiserseller/${ fundraiserGuid }`);

export const deleteFundraiserSeller = async(fundraiserSellerId: number) => api.delete<boolean>(`api/fundraiserseller/${ fundraiserSellerId }`);

export const removeFundraiserSeller = async(fundraiserSellerId: number) => api.delete<boolean>(`api/fundraiserseller/remove/${ fundraiserSellerId }`);

export const activateFundraiserSeller = async(fundraiserSellerId: number) => api.put<boolean>(`api/fundraiserseller/activate/${ fundraiserSellerId }`);

export const fetchFundraisers = async(options: FundraiserSearchOptions) => api.get<PagedList<FundraiserListItem>>(`api/fundraiser?Page=${ options.page }&PageSize=${ options.pageSize }&Keyword=${ encodeURIComponent(options?.keyword ?? '') }&Sort=${ options?.sort ?? 0 }&State=${ options?.state ?? 0 }&SelectedStartDate=${ options?.selectedStartDate || '' }&SelectedEndDate=${ options?.selectedEndDate || '' }`);

export const fetchFundraiserFormData = async(id: number) => api.get<Fundraiser>(`api/fundraiser/formdata/${ id }`);

export const fetchSellerBySupportGuid = async(id: string) => api.get<Seller>(`api/fundraiserseller/support/${ id }`);

export const fetchSellerById = async(fundraiserId: number, customerId: number) => api.get<Seller>(`api/fundraiserseller/${ customerId }/fundraiser/${ fundraiserId }`); // good example

export const fetchSellerByFundraiserId =
  async(id: string) => api.get<Seller[]>(`api/fundraiserseller/${ id }`);

export const updateFundraiser =
  async(fundraiser: FundraiserListItem) => api.put<boolean>('api/fundraiser', fundraiser);

export const updateSellerActivity = async(fundraiserSellerId: number, activity: boolean) => api.put<boolean>(`api/fundraiserseller/activity/${ fundraiserSellerId }?activity=${ activity }`);

export const updateSeller =
  async(seller: UpdateSellerRequest) => api.put('api/fundraiserseller', seller);

export const fetchFilteredReport = async(filterOtions: IReportFilterOptions) => api.download<Blob>(`api/report/${ GenerateReportFiltersQuery(filterOtions) }`);

export const fetchSalesCustomers = async() => api.get<ISalesCustomer[]>('api/salesagent/sales-customers'); // Fetch all Sales Agents and ISCs

export const updateFundraiserEmailPreferences = async(data: {fundraiserPreferences: FundraiserEmailPreferences }) => api.put<boolean>('api/notifications/email', data.fundraiserPreferences);

export const fetchFundraiserQRCode = async(fundraiserId: number) => api.get<string>(`api/fundraiser/${ fundraiserId }/fundraiserqrcode`);

export const fetchSellerQRCode = async(sellerId: number | undefined) => api.get<string>(`api/fundraiserseller/${ sellerId }/fundraiserSellerQRCode`);

export const fetchChairpersonDashboardStatistics = async(fundraiserId: number) => api.get<Stat[]>(`api/Statistics/GetChairpersonDashboardStatistics/${ fundraiserId }`);

export const fetchSellerDashboardStatistics = async(sellerId: number | undefined) => api.get<Stat[]>(`api/Statistics/GetSellerDashboardStatistics/${ sellerId }`);

export const updateFundraiserProfitCheckAddress = async(data: FundraiserProfitCheckAddress) => api.put<boolean>('api/Fundraiser/mealdeal/address', data);

export const fetchFundraiserOrderSummary = async(fundraiserId: number) => api.get<FundraiserOrderSummary>(`api/fundraiser/${ fundraiserId }/finalorder/products`);

export const createFinalOrder = async(request: FundraiserFinalOrder) => api.post<FundraiserSummary>(`api/fundraiser/finalorder/${ request.fundraiserId }`, request.kitOrders);

export const fetchExistingCoupons = async(fundraiserId: number) => api.get<ExistingCoupon[]>(`api/fundraiser/${ fundraiserId }/FinalOrder/Coupons`);

export const applyCouponsToFinalOrder = async(request: CouponsAppliedToFinalOrder) => api.put<ApiResponse<boolean>>(`api/fundraiser/${ request.fundraiserId }/FinalOrder/Coupons`, request.appliedCoupons);

export const fetchFinalOrderSummary = async(fundraiserId: number) => api.get<FinalOrderSummary>(`api/fundraiser/${ fundraiserId }/FinalOrder/profitsummary`);

export const updateFinalOrderInformation = async(formData: FinalOrderInfo) => api.put<ApiResponse<boolean>>(`api/fundraiser/${ formData.fundraiserId }/FinalOrder/Information`, formData.finalOrderInformation);

export const fetchOnlineOrderReport = async(fundraiserId: number) => api.download<Blob>(`api/fundraiser/${ fundraiserId }/onlineordersreport`);
