/* eslint-disable max-lines-per-function */
import * as React from 'react';
import {
  Box, Flex, Form, Text, Image, Button, Spinner,
} from '@lce/slice_v2';
import { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from '@lce/i18n';

import { BuyerListSkeleton } from './BuyerListSkeleton';
import BuyerListItem from './BuyerListItem/BuyerListItem';

import Spear from 'assets/images/spear.svg';
import { SearchBar } from 'ui/common/SearchBar';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import CaesarCash from 'assets/images/caesar-cash.svg';
import { BuyerFilter, BuyerListSortFields } from 'features/fundraiser/types/buyer';
import { Paginate } from 'ui/common/Paginate';
import { useUserInfo } from 'features/common';
import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { useFetchBuyerReport } from 'features/fundraiser/hooks/useFetchBuyerReport';
import { FundraiserType, useFetchPaginatedBuyers } from 'features/fundraiser';
import { useStatefulFetchFundraiserById } from 'ui/hooks';

export interface MatchParams {
  fundraiserId: string;
  customerId: string;
}

export type IBuyerListProps = RouteComponentProps<MatchParams>;

const BuyerList: React.FC<IBuyerListProps> = ({ match }) => {
  const fundraiserId = parseInt(match.params.fundraiserId);
  const customerId = parseInt(match.params.customerId);
  const { authState } = useOktaAuth();
  const { data: user } = useUserInfo(authState.isAuthenticated);
  const [ t ] = useTranslation();
  const [ useBuyerReport, setBuyerReport ] = useState<boolean>(false);
  const [ canRunReport, setCanRunReport ] = useState<boolean>(false);
  const [ searchValue, setSearchValue ] = useState<string>('');
  const [ options, setOptions ] = useState<BuyerFilter>({
    SortByColumn: BuyerListSortFields.OrderDate,
    PageIndex: 1,
    PageSize: 5,
  });
  const {
    data: fundraiser,
    isLoading: isFundraiserLoading,
  } = useStatefulFetchFundraiserById(match.params.fundraiserId);
  const isBrochure = fundraiser?.FundraiserType === FundraiserType.Brochure;
  useFetchBuyerReport(fundraiserId, customerId, useBuyerReport);

  useEffect(() => {
    if (user) {
      setCanRunReport(user.Roles.includes(FundraiserDefaults.SuperAdminRoleName) ||
        user.Roles.includes(FundraiserDefaults.SellerRoleName) || false);
    }
  }, [ user ]);

  const {
    data: resolvedData, isSuccess, isFetching, error,
  } = useFetchPaginatedBuyers(fundraiserId, customerId, options);

  const onPageSelectHandler = (page: number) => {
    setOptions({ ...options, PageIndex: page });
  };

  const onPageSizeSelectHandler = (size: number) => {
    setOptions({ ...options, PageIndex: 1, PageSize: size });
  };

  const onSortChangeHandler = (value: number) => {
    setOptions({ ...options, SortByColumn: value });
  };

  const onSearchChangeHandler = (value: string) => {
    setOptions({ ...options, PageIndex: 1, Filter: value });
  };

  if (error?.Status === 403) {
    return <Redirect to="/403" />;
  }

  if (isFetching || isFundraiserLoading) {
    return <Spinner variant="lce" />;
  }

  return (
    <Box data-testid="buyer-list" sx={ { maxWidth: '855px', marginTop: [ '84px', '116px', '30px', 0 ] } }>
      <FundraiserNavigation
        customerId={ `${ customerId }` }
        fundId={ `${ fundraiserId }` }
        url={ match.url }
      />
      {isSuccess && resolvedData ? (
        <Box sx={ { mt: '24px' } }>
          {resolvedData.Items?.length > 0 && (
            <>
              <Flex
                sx={ {
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: [ 'flex-start', 'center', 'center' ],
                  flexDirection: [ 'column', 'row', 'row' ],
                } }
              >
                <Text variant="text.header.sub">
                  {`${ t('dashboard.buyerList.buyers') } ${ resolvedData.BuyerCount } / 
              ${ t('dashboard.buyerList.orders') } ${ resolvedData.TotalCount }`}
                </Text>
                {canRunReport && !isBrochure && (
                  <Button
                    data-testid="buyer-report-button"
                    onClick={ () => setBuyerReport(true) }
                    sx={ { width: [ '100%', 'auto', 'auto' ], mt: [ '5px', 0, 0 ] } }
                    variant="primary"
                  >
                    { t('dashboard.buyerList.runReport') }
                  </Button>
                ) }
              </Flex>
              <Flex sx={ { justifyContent: 'space-between', flexDirection: [ 'column', 'row' ] } }>
                <SearchBar
                  onSearch={ onSearchChangeHandler }
                  placeholderText={ t('dashboard.buyerList.searchBy') }
                  searchValue={ searchValue }
                  setSearchValue={ setSearchValue }
                />
                <Flex sx={ { alignItems: 'flex-end', ml: [ '0px', '30px' ], mt: [ '16px', '0px' ] } }>
                  <Form.Select
                    id="order-sort-selector"
                    name="order-sort-selector"
                    onChange={ q => onSortChangeHandler(Number(q.target.value)) }
                    sx={ { padding: '0px 8px', width: [ '240px', '340px' ] } }
                    variant="categorySelector"
                  >
                    <option key={ BuyerListSortFields.OrderDate } value={ BuyerListSortFields.OrderDate }>
                      { t('dashboard.buyerList.searchSortOptions.date') }
                    </option>
                    <option key={ BuyerListSortFields.FullName } value={ BuyerListSortFields.FullName }>
                      { t('dashboard.buyerList.searchSortOptions.name') }
                    </option>
                    <option key={ BuyerListSortFields.KitsPurchased } value={ BuyerListSortFields.KitsPurchased }>
                      { t('dashboard.buyerList.searchSortOptions.kits') }
                    </option>
                  </Form.Select>
                </Flex>
              </Flex>
            </>
          )}
          {resolvedData.Items?.length > 0 && (
            <Flex sx={ { flexDirection: 'column' } }>
              <Flex
                sx={ {
                  justifyContent: 'space-between', padding: '0px 30px', mt: '24px', display: [ 'none', 'flex' ],
                } }
              >
                <Text sx={ { color: 'primaryGrayDark' } }>
                  { t('dashboard.buyerList.searchOptions.name') }
                </Text>
                <Text sx={ { color: 'primaryGrayDark' } }>
                  { t('dashboard.buyerList.searchOptions.email') }
                </Text>
                <Text sx={ { color: 'primaryGrayDark' } }>
                  { t('dashboard.buyerList.searchOptions.date') }
                </Text>
              </Flex>
            </Flex>
          )}
          {resolvedData.Items?.length > 0
            ? (
              <Flex sx={ { minHeight: '700px', flexDirection: 'column' } }>
                {
                  resolvedData.Items.map(order => (
                    <Box key={ order.OrderInfo.Id }>
                      <BuyerListItem order={ order } />
                    </Box>
                  ))
                }
                <Flex sx={ { alignItems: 'flex-end', ml: [ '0px', '30px' ], mt: [ '16px', '0px' ] } } />
                <Paginate
                  currentPage={ options.PageIndex }
                  onPageChange={ onPageSelectHandler }
                  onPageSizeChange={ onPageSizeSelectHandler }
                  pageSize={ options.PageSize }
                  totalCount={ resolvedData.TotalCount }
                />
              </Flex>
            )
            : (
              <Flex
                sx={ {
                  flexDirection: 'column',
                  textAlign: 'center',
                  padding: '40px',
                  pb: '0px',
                  boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.25)',
                  borderRadius: '4px',
                  margin: '10px 0px 30px 0px',
                } }
              >
                { !options.Filter && !isFetching ? (
                  <>
                    <Text variant="text.fundraiserDetail.buyerListEmptyStateHeader">
                      { t('dashboard.buyerList.searchNone.title') }
                    </Text>
                    <Text sx={ { mt: '10px' } }>
                      { t('dashboard.buyerList.searchNone.subtitle') }
                    </Text>
                    <Image src={ CaesarCash } />
                  </>
                ) : (
                  <>
                    <Text variant="text.fundraiserDetail.buyerListEmptyStateHeader">
                      { t('dashboard.buyerList.searchError.title') }
                    </Text>
                    <Text sx={ { mt: '10px' } }>
                      { t('dashboard.buyerList.searchError.subtitle') }
                    </Text>
                    <Image src={ Spear } />
                  </>
                )}
              </Flex>
            )}

        </Box>
      )
        : (<BuyerListSkeleton />)}
    </Box>
  );
};

export default BuyerList;
