/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Redirect, Route, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';
import { useFeature } from '@growthbook/growthbook-react';

import { AuthenticatedRoute, RouteWrapper } from 'ui/routing';
import { LayoutWithoutFooter, MainLayout } from 'ui/common/Layouts/Layouts';
import { BrochureCreatePage } from 'ui/brochure';
import NewFundraiserFormConfirm from 'ui/fundraiser/NewFundraiserForm/NewFundraiserFormConfirm';

export const BrochureRoutes: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const isBrochureProgramDisabled = useFeature('fr_brochure_program_enabled').off;

  if (isBrochureProgramDisabled) {
    return <Redirect to="/404" />;
  }

  //url = brochure/
  return (
    <div data-testid="brochure-routes">
      <Switch>
        <AuthenticatedRoute
          component={ BrochureCreatePage }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/start-a-fundraiser/create` }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />

        <RouteWrapper
          component={ NewFundraiserFormConfirm }
          exact={ true }
          history={ history }
          layout={ MainLayout }
          path={ `${ url }/start-a-fundraiser/confirm` }
          routeComponent={ Route }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />

        <Redirect to="/404" />
      </Switch>
    </div>
  );
};
