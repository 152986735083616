import { useQuery } from 'react-query';

import { fetchOnlineOrderReport } from '../api';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

const useFetchOnlineOrderReport =
  (id: number) => useQuery<Blob, ApiError>(`${ QueryKeys.OnlineOrderReport }-${ id.toString() }`,
    () => fetchOnlineOrderReport(id), {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: false,
    });

export { useFetchOnlineOrderReport };
