import React from 'react';
import {
  Box, Button, Flex, Heading, Image, Spinner, Text,
} from '@lce/slice_v2';
import { match } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';
import { DescriptionOutlined } from '@material-ui/icons';
import { toast } from 'react-toastify';

import anouncerCaesarMan from 'assets/images/Announcer-Caesar-Man.png';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { Step } from 'ui/common';
import { useStatefulFetchFundraiserById } from 'ui/hooks';
import { FundraiserStatus } from 'features/fundraiser/types/fundraiser';
import { useFetchOnlineOrderReport } from 'features/fundraiser';

interface MatchParams {
  fundraiserId: string;
}

export type ViewAndPrintProps = {
  match: match<MatchParams>;
  testId?: string;
}

export const dataTestIdViewAndPrint = 'view-and-print-component';

// eslint-disable-next-line max-lines-per-function
export const ViewAndPrint: React.FC<ViewAndPrintProps> = ({
  match,
  testId = dataTestIdViewAndPrint,
}) => {
  const [ t ] = useTranslation();
  const fundraiserId = match.params.fundraiserId;

  const { refetch } = useFetchOnlineOrderReport(parseInt(fundraiserId));

  const onClickDownload = () => {
    refetch();
  };

  const {
    data: fundraiser,
    isLoading: isFundraiserDataLoading,
    isError: fundraiserFetchError,
  } = useStatefulFetchFundraiserById(fundraiserId);

  if (isFundraiserDataLoading) {
    return (
      <>
        <FundraiserNavigation fundId={ fundraiserId } url={ match.url } />
        <Flex
          data-testid={ `${ testId }-loading` }
          sx={ {
            alignItems: 'center', height: '100vh', justifyContent: 'center', width: '100%',
          } }
        >
          <Spinner variant="lce" />
        </Flex>
      </>
    );
  }

  if (fundraiserFetchError || !fundraiser) {
    toast.error(t('dashboard.viewAndPrint.toast.error'));
    return <FundraiserNavigation fundId={ fundraiserId } url={ match.url } />;
  }

  return (
    <>
      <FundraiserNavigation fundId={ fundraiserId } url={ match.url } />
      {fundraiser.FundraiserStatus === FundraiserStatus.Pending ? (
        <Flex data-testid={ testId } sx={ { flexDirection: 'column', alignItems: 'center', gap: '12px' } }>
          <Image alt="Caesar Man Announcer" src={ anouncerCaesarMan } sx={ { width: [ '157px', '217px' ] } } />
          <Heading as="h1">
            {t('dashboard.viewAndPrint.pending.header')}
          </Heading>
          <Text sx={ { color: 'black', textAlign: 'center' } }>
            {t('dashboard.viewAndPrint.pending.instructions')}
          </Text>
        </Flex>
      ) : (
        <Flex data-testid={ testId } sx={ { gap: '16px', flexDirection: 'column' } }>
          <Heading as="h1">
            {t('dashboard.viewAndPrint.header')}
          </Heading>
          <Text sx={ { color: 'primaryGrayText' } }>
            {t('dashboard.viewAndPrint.instructions')}
          </Text>
          <Step sx={ { mt: 0 } } title={ t('dashboard.viewAndPrint.print') }>
            <Flex
              sx={ {
                width: '100%',
                justifyContent: [ 'center', 'space-between' ],
                p: [ '16px', '32px' ],
                flexDirection: [ 'column', 'row' ],
                gap: '16px',
              } }
            >
              <Flex sx={ { gap: '8px', alignItems: 'center' } }>
                <DescriptionOutlined />
                <Text sx={ { fontWeight: 'bold' } }>
                  {t('dashboard.viewAndPrint.pdfName')}
                </Text>
              </Flex>
              <Button data-testid="download-pdf-button" onClick={ onClickDownload }>
                {t('dashboard.viewAndPrint.download')}
              </Button>
            </Flex>
          </Step>
          <Text sx={ { color: 'primaryGrayText' } }>
            {t('dashboard.viewAndPrint.mealDealDisclaimer')}
          </Text>
          <Box
            sx={ {
              border: '2px solid',
              borderColor: 'primary',
              borderRadius: '10px',
              boxShadow: '0 0 10px 0 rgba(136, 136, 136, 0.25)',
              p: [ '16px', '32px' ],
            } }
          >
            <Text sx={ { color: 'primaryGrayText', fontWeight: 'bold', fontSize: '18px' } }>
              {t('dashboard.viewAndPrint.note')}
            </Text>
          </Box>
        </Flex>
      )}
    </>
  );
};

