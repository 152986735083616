/* eslint-disable max-lines-per-function */
import React from 'react';
import { Link } from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';

export type TextToLink = {
  text: string;
  url: string;
  index?: number;
}

export type InlineLinkParseAndReplaceProps = {
  testId?: string;
  sx?: ThemeUIStyleObject;
  text: string;
  wordsToLink: TextToLink[];
}

type TextWithLinks = {
  text: string;
  url?: string;
}

export const dataTestIdInlineLinkParseAndReplace = 'inline-link-parse-and-replace-component';

export const InlineLinkParseAndReplace: React.FC<InlineLinkParseAndReplaceProps> = ({
  testId = dataTestIdInlineLinkParseAndReplace,
  sx = { color: 'primaryOrange' },
  text,
  wordsToLink,
}) => {
  const phonePattern = /(1-|\+1 )?(?:\([2-9]{1}[0-9]{2}\)\s*|[2-9]{1}[0-9]{2}-)[0-9]{3}( )?-[0-9]{4}/g;
  const phoneNumbers = text.match(phonePattern);
  const hasPhoneNumbers = phoneNumbers && phoneNumbers.length > 0;
  const phoneNumbersToLink: TextToLink[] = _map(phoneNumbers, number => ({ text: number, url: `tel:${ number }` }));

  //TODO: Add support for if the same phone number appears multiple times in the text
  const phoneNumbersToLinkIndexes: number[] = [];
  _forEach(phoneNumbersToLink, (number) => {
    const escKeys = [ '(', ')', '+' ];
    let escapedText = number.text;
    _forEach(escKeys, key => escapedText = escapedText.replaceAll(key, `\\${ key }`));
    const startIndex = text.search(escapedText);
    const endIndex = startIndex + number.text.length;
    phoneNumbersToLinkIndexes.push(startIndex);
    phoneNumbersToLinkIndexes.push(endIndex);
  });

  const phoneNumberIndexes = hasPhoneNumbers ? phoneNumbersToLinkIndexes : [];

  const wordToLinkIndexes: number[] = [];
  _forEach(wordsToLink, (word) => {
    const findWord = new RegExp(`\\b${ word.text }\\b`, 'g');
    let startIndex = text.search(findWord);
    let endIndex = startIndex + word.text.length;
    const wordMatchesCount = text.match(findWord)?.length || 0;
    if (wordMatchesCount > 1 && !word.index && process.env.NODE_ENV !== 'production') {
      console.error(`The word "${ word.text }" appears multiple times in the text but no index was provided`);
    }
    if (word.index && word.index > 0 && wordMatchesCount >= word.index) {
      for (let i = 0;i < word.index;i++) {
        const slicedText = text.slice(endIndex);
        startIndex = endIndex + slicedText.search(findWord);
        endIndex = startIndex + word.text.length;
      }
    }
    wordToLinkIndexes.push(startIndex);
    wordToLinkIndexes.push(endIndex);
  });

  const indexes = [ 0, ...phoneNumberIndexes, ...wordToLinkIndexes, text.length ].sort((a, b) => a - b);

  const splitTextOnIndexes: string[] = [];
  for (let i = 0;i < indexes.length - 1;i++) {
    splitTextOnIndexes.push(text.slice(indexes[i], indexes[i + 1]));
  }

  const TextWithLinks: TextWithLinks[] = _map(splitTextOnIndexes, (splitText) => {
    const phoneNumberToLink = phoneNumbersToLink.find(number => number.text === splitText);
    const wordToLink = wordsToLink.find(word => word.text === splitText);
    if (phoneNumberToLink) {
      return {
        ...phoneNumberToLink,
        text: phoneNumberToLink.text.replace(/-/g, '‑'),
      };
    }
    if (wordToLink) {
      return wordToLink;
    }
    return {
      text: splitText,
    };
  });

  return (
    <>
      {_map(TextWithLinks, ({ text, url }, index) => {
        if (url) {
          return (
            <Link
              data-testid={ `${ testId }-${ index }` }
              href={ url }
              key={ index }
              sx={ sx }
            >
              {text}
            </Link>
          );
        } else {
          return (
            <React.Fragment key={ index }>
              { text }
            </React.Fragment>
          );
        }
      })}
    </>
  );
};
