import React from 'react';
import {
  RouteProps, Switch, useRouteMatch, useHistory,
  Redirect,
} from 'react-router-dom';
import { Box, Container, Spinner } from '@lce/slice_v2';
import { useOktaAuth } from '@okta/okta-react';
import { useFeature } from '@growthbook/growthbook-react';

import { FundraiserList } from './myFundraisers/fundraiserList';
import { FinalOrderPage } from './myFundraisers/final-order-page';
import Reports from './reports';
import Orders from './orders';
import Settings from './settings';
import { FundraiserConfirmation } from './myFundraisers/fundraiserConfirmation';
import { FundraiserDetail } from './myFundraisers/fundraiserDetail';
import { SellerDetail } from './sellerDetail';
import FundraiserSellerReport from './fundraiserSellerReport';
import FundraiserEdit from './myFundraisers/fundraiserEdit';
import ManageSellersPage from './myFundraisers/fundraiserManageSellers';
import ResourceHubPage from './myFundraisers/resourceHub';
import { ViewAndPrint } from './myFundraisers/view-and-print';

import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { NoLayout } from 'ui/common';
import { useUserInfo } from 'features/common/hooks/useUserInfo';
import { UserInfo } from 'features/common/types/userInfo';
import { BuyerList } from 'ui/sellerDetail/BuyerList';
import { PrivateRouteWrapper, RouteWrapper } from 'ui/routing';

export type IDashboardProps = RouteProps

// eslint-disable-next-line max-lines-per-function
const Dashboard: React.FC<IDashboardProps> = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const isBrochureProgramEnabled = useFeature('fr_brochure_program_enabled').on;

  const { authState } = useOktaAuth();
  const { data: user } = useUserInfo(authState.isAuthenticated);

  if (user !== undefined) {
    return (
      <Box sx={ { pl: [ '15px', '15px', '95px' ], px: '15px' } }>
        <Switch>
          <RouteWrapper
            component={ () => <FundraiserList user={ user } /> }
            exact={ true }
            history={ history }
            layout={ NoLayout }
            path={ `${ path }/fundraisers` }
            seo={ { pageTitle: 'My Fundraisers | Little Caesars Fundraising' } }
          />

          <RouteWrapper
            component={ FundraiserDetail } exact={ true }
            history={ history }
            layout={ NoLayout }
            path="/dashboard/fundraisers/manage/:fundraiserId"
            seo={ { pageTitle: 'Fundraiser Home | Little Caesars Fundraising' } }
          />

          {isBrochureProgramEnabled && (
            <RouteWrapper
              component={ FundraiserConfirmation }
              exact={ true }
              history={ history }
              layout={ NoLayout }
              path="/dashboard/fundraisers/final-order/confirmation/:fundraiserId"
              seo={ { pageTitle: 'Final Order Confirmation | Little Caesars Fundraising' } }
            />
          )}

          {isBrochureProgramEnabled && (
            <RouteWrapper
              component={ FinalOrderPage } exact={ true }
              history={ history }
              layout={ NoLayout }
              path="/dashboard/fundraisers/final-order/:fundraiserId"
              seo={ { pageTitle: 'Final Order | Little Caesars Fundraising' } }
            />
          )}

          {isBrochureProgramEnabled && (
            <RouteWrapper
              component={ ViewAndPrint }
              exact={ true }
              history={ history }
              layout={ NoLayout }
              path="/dashboard/fundraisers/view-and-print/:fundraiserId"
              seo={ { pageTitle: 'View & Print Order | Little Caesars Fundraising' } }
            />
          )}

          <PrivateRouteWrapper
            component={ Reports }
            history={ history } layout={ NoLayout }
            path={ `${ path }/reports` } roles={ [ FundraiserDefaults.SuperAdminRoleName ] }
            seo={ { pageTitle: 'Reports | Little Caesars Fundraising' } }
          />

          <RouteWrapper
            component={ Orders } history={ history }
            layout={ NoLayout } path={ `${ path }/my-orders` }
            seo={ { pageTitle: 'My Orders | Little Caesars Fundraising' } }
          />

          <RouteWrapper
            component={ FundraiserEdit } exact={ true }
            history={ history }
            layout={ NoLayout }
            path="/dashboard/fundraisers/edit/:fundraiserId"
            seo={ { pageTitle: 'Edit Fundraiser | Little Caesars Fundraising' } }
          />

          <RouteWrapper
            component={ ManageSellersPage } exact={ true }
            history={ history }
            layout={ NoLayout }
            path="/dashboard/fundraisers/manage-sellers/:fundraiserId"
            seo={ { pageTitle: 'Sellers | Little Caesars Fundraising' } }
          />

          <RouteWrapper
            component={ () => (
              <Settings
                user={ user as UserInfo }
              />
            ) }
            history={ history }
            layout={ NoLayout }
            path={ `${ path }/settings` }
            seo={ { pageTitle: 'Settings | Little Caesars Fundraising' } }
          />

          <RouteWrapper
            component={ SellerDetail }
            history={ history }
            layout={ NoLayout }
            path={ `${ path }/fundraisers/:fundraiserId/seller/:customerId` }
            seo={ { pageTitle: 'Seller | Little Caesars Fundraising' } }
          />

          <RouteWrapper
            component={ BuyerList }
            history={ history }
            layout={ NoLayout }
            path={ `${ path }/fundraisers/:fundraiserId/buyers/:customerId` }
            seo={ { pageTitle: 'Buyers | Little Caesars Fundraising' } }
          />

          <RouteWrapper
            component={ FundraiserSellerReport }
            history={ history }
            layout={ NoLayout }
            path={ `${ path }/fundraisers/seller-report/:fundraiserId` }
            seo={ { pageTitle: 'Seller Report | Little Caesars Fundraising' } }
          />

          <RouteWrapper
            component={ ResourceHubPage }
            history={ history }
            layout={ NoLayout }
            path="/dashboard/fundraisers/:fundraiserId/resource-hub/:customerId"
          />

          <RouteWrapper
            component={ ResourceHubPage }
            exact={ true }
            history={ history }
            layout={ NoLayout }
            path="/dashboard/fundraisers/:fundraiserId/resource-hub"
          />

          <Redirect to="/" />

        </Switch>

      </Box>
    );
  } else {
    return (
      <Container sx={ { textAlign: 'center' } }>
        <Spinner variant="lce" />
      </Container>
    );
  }
};

export default Dashboard;
