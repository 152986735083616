import React from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Box, Button, Link, Text,
} from '@lce/slice_v2';
import { format } from 'date-fns';
import { isNil as _isNil } from 'lodash';

import Invite from '../../Invite';

import { PhoneNumberLinkWrapper } from './PhoneNumberLinkWrapper';

import { IFundraiserSupporterProps, IStepFundraiserProp } from 'features/fundraiser/types/dashboard';
import { useIsUpcomingFundraiser } from 'features/fundraiser/hooks/useIsUpcomingFundraiser';
import { FinalOrderStatus, FundraiserStatus } from 'features/fundraiser/types/fundraiser';

export const CompleteYourProfile = () => {
  const [ t ] = useTranslation();
  return (
    <Text sx={ { mx: 3 } }>
      { t('dashboard.steps.chairperson.step1.content')}
    </Text>
  );
};

export const InviteSellers: React.FC<IStepFundraiserProp> = ({ fundraiser, idSuffix }) => (
  <Invite fundraiser={ fundraiser } idSuffix={ idSuffix } showSocials={ false } />);

export const ManageYourSellers: React.FC<IStepFundraiserProp> = ({ fundraiser }) => {
  const [ t ] = useTranslation();
  return (
    <>
      <Text sx={ { mx: 3 } }>
        {t('dashboard.steps.chairperson.step3.content') }
      </Text>
      <Box
        sx={ {
          m: [ 2, 0 ], textAlign: 'right',
          position: [ 'relative', 'absolute' ], right: [ 'initial', '28px' ], bottom: [ 'initial', '24px' ],
        } }
      >
        <Link
          href={ `${ t('dashboard.steps.chairperson.step3.linkUrl') }${ fundraiser.Id }` }
          id={ `click-${ t('dashboard.steps.chairperson.step3.linkText').replace(' ', '-').toLowerCase() }` }
          sx={ { color: 'primaryOrange', textDecoration: 'none', fontWeight: 'bold' } }
        >
          {t('dashboard.steps.chairperson.step3.linkText')}
        </Link>
      </Box>
    </>
  );
};

// eslint-disable-next-line max-len
export const FundraiserSupporters: React.FC<IFundraiserSupporterProps> = ({
  fundraiser, idSuffix, sellerId, sellerSupportGuid,
}) => {
  const [ t ] = useTranslation();
  const isFundraiserUpcoming = useIsUpcomingFundraiser(fundraiser);

  if (sellerSupportGuid && sellerId) {
    return (
      <Invite
        customUpcomingText={ t('dashboard.steps.chairperson.step4.checkBack') } fundraiser={ fundraiser }
        idSuffix={ idSuffix } sellerId={ sellerId }
        sellerSupportGuid={ sellerSupportGuid }
      />
    );
  } else {
    return (
      isFundraiserUpcoming ? (
        <Box
          sx={ {
            width: '100%',
            border: '1px solid orange',
            borderRadius: '3px',
            padding: '20px',
          } }
        >
          <strong>
            {t('dashboard.steps.chairperson.step4.checkBack')}
          </strong>
        </Box>
      ) : (
        <>
          <Text sx={ { mx: 3, mb: 5 } }>
            {t('dashboard.steps.chairperson.step4.bodyText')}
          </Text>
          <Button
            as="a"
            href={ `${ window.location.origin }/login/join-fundraiser/${ fundraiser.FundraiserGuid }` }
            sx={ { pt: '10px' } }
          >
            {t('dashboard.steps.chairperson.step4.buttonText')}
          </Button>
        </>
      )
    );
  }
};

export const PromoteYourFundraiser = ({ fundraiser }) => {
  const [ t ] = useTranslation();
  const resourceHubUrl = t('dashboard.steps.chairperson.promoteYourFundraiser.linkUrl',
    { fundraiserId: fundraiser.Id });

  return (
    <>
      <Text sx={ { mx: 3 } }>
        {t('dashboard.steps.chairperson.promoteYourFundraiser.content') }
      </Text>
      <Box
        sx={ {
          m: [ 2, 0 ], textAlign: 'right',
          position: [ 'relative', 'absolute' ], right: [ 'initial', '28px' ], bottom: [ 'initial', '24px' ],
        } }
      >
        <Link
          href={ resourceHubUrl }
          sx={ { color: 'primaryOrange', textDecoration: 'none', fontWeight: 'bold' } }
        >
          {t('dashboard.steps.chairperson.promoteYourFundraiser.linkText')}
        </Link>
      </Box>
    </>
  );
};

export const ReceiveYourProfitCheck = ({ handleAddressModal, isExpired, isMissingProfitCheckAddress }) => {
  const [ t ] = useTranslation();

  const context = isMissingProfitCheckAddress ? 'NoAddress' : 'Address';

  const content = isExpired
    ? t('dashboard.steps.chairperson.receiveYourProfitCheck.fundraiserHasExpired', { context })
    : t('dashboard.steps.chairperson.receiveYourProfitCheck.content', { context });

  const linkText = t('dashboard.steps.chairperson.receiveYourProfitCheck.linkText', { context });

  const hideLink = isExpired && !isMissingProfitCheckAddress;

  return (
    <>
      <Text sx={ { mx: 3 } }>
        {content}
      </Text>
      <Box
        sx={ {
          m: [ 2, 0 ], textAlign: 'right',
          position: [ 'relative', 'absolute' ], right: [ 'initial', '28px' ], bottom: [ 'initial', '24px' ],
        } }
      >
        {hideLink
          ? null : (
            <Link
              onClick={ handleAddressModal }
              sx={ {
                color: 'primaryOrange', textDecoration: 'none', fontWeight: 'bold', cursor: 'pointer',
              } }
            >
              {linkText}
            </Link>
          )}
      </Box>
    </>
  );
};

// eslint-disable-next-line max-lines-per-function
export const PlaceFinalOrder = ({ fundraiser }) => {
  const [ t ] = useTranslation();
  const fundraiserStatus = FundraiserStatus[fundraiser.FundraiserStatus];
  const finalOrderDetails = fundraiser.FinalOrderDetails;
  const finalOrderStatus = _isNil(finalOrderDetails) ? null : FinalOrderStatus[finalOrderDetails.Status];

  const translationKey = 'dashboard.steps.chairperson.placeFinalOrder.message';
  let text = '';

  if (finalOrderDetails?.FinalOrderDate) {
    text = t(translationKey, {
      context: `${ fundraiserStatus }${ finalOrderStatus }`,
      finalOrderDate: format(new Date(finalOrderDetails?.FinalOrderDate), 'MMMM d, yyyy'),
    });
  } else {
    text = t(translationKey, { context: 'PendingUndefined' });
  }

  const showViewFinalOrderText = finalOrderDetails?.Status === FinalOrderStatus.FinalOrderSubmitted ||
    finalOrderDetails?.Status === FinalOrderStatus.FinalOrderComplete;
  const disableFinalOrderLink = finalOrderDetails?.Status === FinalOrderStatus.Undefined ||
    finalOrderDetails?.Status === FinalOrderStatus.FinalOrderUpcoming;

  const showLink = finalOrderDetails?.Status !== FinalOrderStatus.FinalOrderOverdue;

  if (!_isNil(fundraiserStatus) && !_isNil(finalOrderStatus) && !!text && text !== translationKey) {
    return (
      <>
        <Text data-testid={ `${ fundraiserStatus }${ finalOrderStatus }-content` } sx={ { mx: 3 } }>
          <PhoneNumberLinkWrapper text={ text } />
        </Text>

        <Box
          sx={ {
            m: [ 2, 0 ], textAlign: 'right',
            position: [ 'relative', 'absolute' ], right: [ 'initial', '28px' ], bottom: [ 'initial', '24px' ],
          } }
        >
          {showLink && (
            <Link
              data-testid="step-6-link"
              href={ showViewFinalOrderText
                ? finalOrderDetails?.ViewFinalOrderUrl
                : t('dashboard.steps.chairperson.placeFinalOrder.linkHrefFinalize', { fundraiserId: fundraiser.Id }) }
              onClick={ e => disableFinalOrderLink && e.preventDefault() }
              sx={ {
                color: disableFinalOrderLink ? 'primaryGrayLight' : 'primaryOrange',
                textDecoration: 'none', fontWeight: 'bold', disabled: disableFinalOrderLink,
                ':hover': {
                  color: disableFinalOrderLink ? 'primaryGrayLight' : 'primaryOrange',
                  cursor: disableFinalOrderLink ? 'not-allowed' : 'pointer',
                },
              } }
            >
              {showViewFinalOrderText
                ? t('dashboard.steps.chairperson.placeFinalOrder.linkTextView')
                : t('dashboard.steps.chairperson.placeFinalOrder.linkTextFinalize')}
            </Link>
          )}
        </Box>
      </>
    );
  } else {
    return null;
  }
};
