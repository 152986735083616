/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Box, Card, Flex, Image, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { Button } from 'ui/components/buttons';
import { TopArchClipPathSvg } from 'ui/components/arch-clip-path-svgs';

export type HomeCardProps = {
  role: string;
  image: string;
  icon: string;
}

export const dataTestIdHomeCard = 'home-card-component';

export const UserCategoryCard: React.FC<HomeCardProps> = ({
  role, image, icon,
}) => {
  const [ t ] = useTranslation();
  return (
    <Card
      data-testid={ `${ role }-card` }
      sx={ {
        gridArea: role,
        width: '100%',
        border: 'none',
        transition: 'padding 0.5s ease',
        pt: [ 0, 0, '52px' ],
        '&:hover': {
          pt: 0,
          button: {
            opacity: 1,
            visibility: 'visible',
            mb: '16px',
            height: '36px',
            padding: '8px 12px',
            border: '2px solid white',
            color: 'white',
          },
        },
      } }
    >
      <Flex sx={ { flexDirection: 'column', height: '100%', position: 'relative' } }>
        <Image
          data-testid={ `${ role }-image` }
          src={ image } sx={ {
            width: '100%', height: '215px', objectFit: 'cover', transform: 'translate3d(0, 0, 1px)',
          } }
        />
        <TopArchClipPathSvg />
        <Flex
          sx={ {
            alignItems: 'center',
            backgroundColor: 'primary',
            clipPath: 'url(#top-arch-clip)',
            color: 'white',
            flexDirection: 'column',
            gap: '16px',
            mt: '-18px',
            p: '34px 16px 16px',
            position: 'relative',
            textAlign: 'center',
          } }
        >
          <Box>
            <Text variant="home.card.heading">
              {role}
            </Text>
            <Text
              sx={ { height: [ 'auto', '120px', '96px' ], alignContent: 'center' } }
              variant="home.card.description"
            >
              {t('home.card.description', { context: role })}
            </Text>
          </Box>

          <Button
            data-testid={ `read-more-button-${ role }` }
            href={ `/${ role }` }
            sx={ {
              border: [ '2px solid white', '2px solid white', '0' ],
              color: [ 'white', 'white', 'transparent' ],
              height: [ '36px', '36px', '0' ],
              mb: [ '16px', '16px', '0' ],
              opacity: [ 1, 1, 0 ],
              padding: [ '8px 12px', '8px 12px', '0' ],
              transition: `opacity 0.5s ease 0.1s, margin 0.5s ease, padding 0.5s ease,
                height 0.5s ease, color 0.25s ease 0.1s, border 0.25s ease 0.1s`,
              visibility: [ 'visible', 'visible', 'hidden' ],
              width: '225px',
            } }
            variant="outline"
          >
            {t('home.card.readMore')}
          </Button>
          <Image
            src={ icon }
            sx={ {
              position: 'absolute', bottom: 0, left: 0, zIndex: -1,
            } }
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default UserCategoryCard;
