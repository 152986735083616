import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form, Flex, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { toast } from 'react-toastify';
import _map from 'lodash/map';
import * as Yup from 'yup';

import { Step, GenericToast } from 'ui/common';
import { PhoneInput, phonePattern } from 'ui/components/forms';
import { SubmitButton } from 'ui/components';
import { useUpdateFinalOrderInformation, FinalOrderInfo } from 'features/fundraiser';

import './final-order-information.css';

export type FinalOrderInformationProps = {
  fundraiserId: number;
  onSubmit: (isComplete: boolean) => void;
  testId?: string;
}

export type ManualOrderInformationTotal= {
  productsTotal: Record<number, number>;
  total: number;
}

export const dataTestIdFinalInformationForm = 'final-order-information-component';

// eslint-disable-next-line max-lines-per-function
export const FinalOrderInformation: React.FC<FinalOrderInformationProps> = ({
  fundraiserId,
  onSubmit,
  testId = dataTestIdFinalInformationForm,
}) => {
  const [ t ] = useTranslation();
  const [ showChairperson, setShowChairperson ] = useState(false);
  const { mutateAsync: updateFinalOrderInformation } = useUpdateFinalOrderInformation();
  const history = useHistory();

  const paymentMethodMapping = {
    'Cashier\'s Check': 'cashiersCheck',
    'Money Order': 'moneyOrder',
    'School Check': 'schoolCheck',
  };

  const paymentMethods = t('dashboard.finalOrderPage.finalOrderInformation.payment.options', { returnObjects: true });

  const handleOnSubmit = async(data) => {
    const formData: FinalOrderInfo = {
      fundraiserId,
      finalOrderInformation: {
        PaymentMethod: paymentMethodMapping[data.paymentMethod],
        IsCPForNextFundraiser: data.keepChairperson,
      },
    };
    if (data.emailAddress) {
      formData.finalOrderInformation.EmailAddress = data.emailAddress;
    }
    if (data.newChairpersonName) {
      formData.finalOrderInformation.NewChairpersonName = data.newChairpersonName;
    }
    if (data.newChairpersonPhone) {
      formData.finalOrderInformation.NewChairpersonPhone = data.newChairpersonPhone;
    }
    if (data.newChairpersonEmail) {
      formData.finalOrderInformation.NewChairpersonEmail = data.newChairpersonEmail;
    }

    try {
      await updateFinalOrderInformation(formData);
      onSubmit(true);
      toast(<GenericToast text={ t('dashboard.finalOrderPage.form.toast.success') } />);
      history.push(`/dashboard/fundraisers/final-order/confirmation/${ fundraiserId }`);
    } catch (error) {
      toast.error(t('dashboard.finalOrderPage.form.toast.error'));
    }
  };

  const validationSchema = Yup.object({
    emailAddress: Yup.string()
      .email(t('dashboard.finalOrderPage.finalOrderInformation.email.error')),
    paymentMethod: Yup.string()
      .required(t('dashboard.finalOrderPage.finalOrderInformation.payment.error')),
    keepChairperson: Yup.boolean()
      .required(t('dashboard.finalOrderPage.finalOrderInformation.chairperson.selectionError'))
      .oneOf([ true, false ], t('dashboard.finalOrderPage.finalOrderInformation.chairperson.selectionError')),
    newChairpersonName: Yup.string()
      .when('keepChairperson', {
        is: false,
        then: Yup.string()
          .required(t('dashboard.finalOrderPage.finalOrderInformation.chairperson.name.error'))
          .max(100, t('dashboard.finalOrderPage.finalOrderInformation.chairperson.name.error')),
      }),
    newChairpersonPhone: Yup.string()
      .when('keepChairperson', {
        is: false,
        then: Yup.string()
          .matches(phonePattern, t('dashboard.finalOrderPage.finalOrderInformation.chairperson.phone.requiredError'))
          .required(t('dashboard.finalOrderPage.finalOrderInformation.chairperson.phone.requiredError')),
      }),
    newChairpersonEmail: Yup.string()
      .when('keepChairperson', {
        is: false,
        then: Yup.string()
          .required(t('dashboard.finalOrderPage.finalOrderInformation.chairperson.email.requiredError'))
          .email(t('dashboard.finalOrderPage.finalOrderInformation.chairperson.email.validationError')),
      }),
  });

  return (
    <Step title={ t('dashboard.finalOrderPage.finalOrderInformation.header') }>
      <Form
        onSubmit={ data => handleOnSubmit(data) }
        validationSchema={ validationSchema }
      >
        <Flex data-testid={ testId } sx={ { flexDirection: 'column', gap: [ '16px', '32px' ], p: [ '16px', '32px' ] } }>
          <Flex sx={ { flexDirection: 'column', gap: [ '8px', '16px' ] } }>
            <Text
              sx={ { fontSize: [ '20px', '24px' ], fontWeight: 'bold' } }
            >
              {t('dashboard.finalOrderPage.finalOrderInformation.email.header')}
            </Text>
            <Form.Field
              data-testid={ `${ dataTestIdFinalInformationForm }-additional-email` }
              id="emailAddress"
              label={ undefined }
              name="emailAddress"
              sx={ { my: '0px', width: '100%' } }
              type="text"
            />
          </Flex>
          <Flex sx={ { flexDirection: 'column', gap: [ '8px', '16px' ] } }>
            <Text
              sx={ { fontSize: [ '20px', '24px' ], fontWeight: 'bold' } }
            >
              {t('dashboard.finalOrderPage.finalOrderInformation.payment.header')}
            </Text>
            <Text>
              {t('dashboard.finalOrderPage.finalOrderInformation.payment.content.p1')}
              <span style={ { fontWeight: 800 } }>
                {t('dashboard.finalOrderPage.finalOrderInformation.payment.content.p2')}
              </span>
              {t('dashboard.finalOrderPage.finalOrderInformation.payment.content.p3')}
            </Text>
            <Form.Group name="paymentMethod">
              <Flex sx={ { flexDirection: 'column', gap: [ '8px', '16px' ] } }>
                {_map(paymentMethods, option => (
                  <Form.Field
                    component={ Form.Input.Radio }
                    id={ `${ dataTestIdFinalInformationForm }-payment-option-${ option }` }
                    inline={ true }
                    label={ option }
                    name={ `payment-option-${ option }` }
                    sx={ { m: '0' } }
                    value={ option }
                  />
                ))}
              </Flex>
            </Form.Group>
          </Flex>
          <Flex sx={ { flexDirection: 'column', gap: [ '8px', '16px' ] } }>
            <Text>
              {t('dashboard.finalOrderPage.finalOrderInformation.chairperson.content_newChairperson')}
            </Text>
            <Form.Group name="keepChairperson">
              <Flex sx={ { flexDirection: 'column', gap: [ '8px', '16px' ] } }>
                <Form.Field
                  component={ Form.Input.Radio }
                  id={ `${ dataTestIdFinalInformationForm }-keep-chairperson` }
                  inline={ true }
                  label={ t('dashboard.finalOrderPage.finalOrderInformation.chairperson.keepChairperson') }
                  name={ `${ dataTestIdFinalInformationForm }-keep-chairperson` }
                  onChange={ () => setShowChairperson(false) }
                  sx={ { m: '0' } }
                  value={ true }
                />
                <Form.Field
                  component={ Form.Input.Radio }
                  id={ `${ dataTestIdFinalInformationForm }-change-chairperson` }
                  inline={ true }
                  label={ t('dashboard.finalOrderPage.finalOrderInformation.chairperson.changeChairperson') }
                  name={ `${ dataTestIdFinalInformationForm }-change-chairperson` }
                  onChange={ () => setShowChairperson(true) }
                  sx={ { m: '0' } }
                  value={ false }
                />
              </Flex>
            </Form.Group>
          </Flex>
          {showChairperson && (
            // eslint-disable-next-line max-len
            <Flex data-testid={ `${ dataTestIdFinalInformationForm }-chairperson-info` } sx={ { flexDirection: 'column', gap: [ '8px', '16px' ] } }>
              <Text>
                {t('dashboard.finalOrderPage.finalOrderInformation.chairperson.content_chairpersonInfo')}
              </Text>
              <Form.Field
                data-testid={ `${ dataTestIdFinalInformationForm }-chairperson-name` }
                id="newChairpersonName"
                label={ t('dashboard.finalOrderPage.finalOrderInformation.chairperson.name.label') }
                name="newChairpersonName"
                sx={ { my: '0px', width: '100%' } }
                type="text"
              />
              <PhoneInput
                label={ t('dashboard.finalOrderPage.finalOrderInformation.chairperson.phone.label') }
                name="newChairpersonPhone"
                sx={ { my: '0px', width: '100%' } }
                testId={ `${ dataTestIdFinalInformationForm }-chairperson-phone` }
              />
              <Form.Field
                data-testid={ `${ dataTestIdFinalInformationForm }-chairperson-email` }
                id="newChairpersonEmail"
                label={ t('dashboard.finalOrderPage.finalOrderInformation.chairperson.email.label') }
                name="newChairpersonEmail"
                sx={ { my: '0px', width: '100%' } }
                type="text"
              />
            </Flex>
          )}
          <SubmitButton
            data-testid={ `${ dataTestIdFinalInformationForm }-submit-btn` }
            sx={ { width: '100%' } } variant="primary.large"
          >
            {t('dashboard.finalOrderPage.finalOrderInformation.submitButton')}
          </SubmitButton>
        </Flex>
      </Form>
    </Step>
  );
};
