import React, { useEffect, useState } from 'react';
import { map as _map } from 'lodash';
import { Flex, Box } from '@lce/slice_v2';
import { ChevronDown, ChevronUp } from 'react-feather';

import { LinkItem } from '../LinkItem';

import { useFundraiserDispatch, useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { useMediaQuery } from 'features/common';
import { useFeatureFlaggedNavigationLinks } from 'ui/hooks';

export interface IFundraiserNavigation {
  sidebar?: boolean;
  fundId?: string;
  url?: string;
  customerId?: string;
}

// eslint-disable-next-line max-lines-per-function
const FundraiserNavigation: React.FC<IFundraiserNavigation> = ({
  sidebar, fundId, url, customerId,
}) => {
  type TLink = {
    title: string;
    path: string;
  }

  const isLarge = useMediaQuery('(min-width: 960px)');
  const fundraiserState = useFundraiserState();
  const fundraiserDispatch = useFundraiserDispatch();
  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  const { fundraiserId, fundraiserType } = fundraiserState;
  const [ activeIndex, setActiveIndex ] = useState<number>(0);
  const links: TLink[] = useFeatureFlaggedNavigationLinks({ fundraiserId, fundraiserType, customerId });

  useEffect(() => {
    if (!sidebar && !fundraiserId) {
      fundraiserDispatch({ type: 'SET_FUNDRAISER_ID', fundraiserId: parseInt(fundId ? fundId : '') });
    }
  }, [ fundId, fundraiserDispatch, fundraiserId, sidebar ]);

  useEffect(() => {
    _map(links, (link, index) => {
      if (url && url.includes(link.path) && activeIndex !== index) {
        setActiveIndex(index);
      }
    });
  }, [ activeIndex, fundraiserDispatch, links, url ]);

  const handleClick = (clickedLink) => {
    if (isOpen) {
      setActiveIndex(clickedLink);
      setIsOpen(false);
    } else if (!isOpen && activeIndex === clickedLink) {
      setIsOpen(true);
    }
  };

  // eslint-disable-next-line max-lines-per-function
  const MobileNav = () => (
    <Flex sx={ { justifyContent: 'center' } }>
      <Flex
        sx={ {
          height: isOpen ? 'auto' : '64px',
          overflowY: 'hidden',
          width: [ '100%', '347px' ],
          maxWidth: '347px',
          zIndex: 1,
        } }
        variant="links.dashboardMobileNav"
      >
        {!isOpen && (
          <Box
            data-testid="not-open-nav"
            onClick={ () => handleClick(activeIndex) }
            sx={ {
              color: 'primaryOrange',
              fontWeight: 'bold',
              ml: 15,
              position: 'unset',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              py: 0,
              my: 0,
            } }
            variant="links.dashboardNavLink"
          >
            {links[activeIndex].title}
          </Box>
        )}
        {isOpen && (
          <Box sx={ { width: '100%' } }>
            {_map(links, (link, index) => (
              <LinkItem
                data-testid={ link.title }
                key={ link.title }
                onClick={ () => handleClick(index) }
                path={ link.path }
                text={ link.title }
              />
            ))}
          </Box>
        )}
        <Box sx={ { mt: 24, mr: 24 } }>
          {isOpen
            ? (
              <ChevronUp
                color="#FF671B" data-testid="chevron-not-open" height="16px"
                onClick={ () => handleClick(activeIndex) } strokeWidth="3" width="16px"
              />
            )
            : (
              <ChevronDown
                color="#FF671B" data-testid="chevron-open" height="16px"
                onClick={ () => handleClick(activeIndex) } strokeWidth="3" width="16px"
              />
            )}
        </Box>
      </Flex>
    </Flex>
  );

  const DesktopNav = () => (
    <Box>
      {_map(links, (link: TLink) => (
        <LinkItem
          data-testid={ `desktop-${ link.title }` }
          key={ link.title }
          path={ link.path }
          text={ link.title }
        />
      ))}
    </Box>
  );

  return (
    <>
      {!sidebar && !isLarge && <MobileNav />}
      {sidebar && isLarge && <DesktopNav />}
    </>
  );
};

export default FundraiserNavigation;
