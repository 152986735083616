/* eslint-disable max-lines-per-function */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Box, Button, Flex, Form, Option, Spinner, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';
import { XCircle } from 'react-feather';
import { toast } from 'react-toastify';

import { GenericToast, Step } from 'ui/common';
import {
  CouponsAppliedToFinalOrder, ExistingCoupon, useFetchExistingCoupons, useApplyCouponsToFinalOrder,
} from 'features/fundraiser';

export type ApplyCouponFormProps = {
  fundraiserId: number;
  testId?: string;
}

export const dataTestIdApplyCouponForm = 'apply-coupon-form-component';

export const ApplyCouponForm: React.FC<ApplyCouponFormProps> = ({
  fundraiserId,
  testId = dataTestIdApplyCouponForm,
}) => {
  const { t } = useTranslation();
  const { data: existingCoupons, isLoading, isError } = useFetchExistingCoupons(fundraiserId);
  const { mutateAsync: applyFinalOrderCouponsMutation } = useApplyCouponsToFinalOrder();

  const [ selectedCouponCode, setSelectedCouponCode ] = useState<string>('');
  const [ appliedCouponCodes, setAppliedCouponCodes ] = useState<string[]>([]);

  useEffect(() => {
    const appliedCouponsDefault: ExistingCoupon[] = _filter(existingCoupons, coupon => coupon.Applied);
    const appliedCouponCodesDefault: string[] = _map(appliedCouponsDefault, coupon => coupon.Code);
    setAppliedCouponCodes(appliedCouponCodesDefault);
  }, [ existingCoupons ]);

  const isSelectedCouponApplied = useCallback(() => _includes(appliedCouponCodes, selectedCouponCode),
    [ selectedCouponCode, appliedCouponCodes ]);

  const areAllCouponsApplied = useCallback(() => existingCoupons &&
    appliedCouponCodes.length === existingCoupons.length,
  [ existingCoupons, appliedCouponCodes ]);

  const handleAddCoupon = () => {
    setAppliedCouponCodes([ ...appliedCouponCodes, selectedCouponCode ]);
    setSelectedCouponCode('');
  };

  const handleRemoveCoupon = (coupon: string) => {
    setAppliedCouponCodes(appliedCouponCodes.filter(appliedCoupon => appliedCoupon !== coupon));
  };

  const onSubmit = async() => {
    const appliedCoupons = _map(existingCoupons, (coupon) => {
      const Applied = _includes(appliedCouponCodes, coupon.Code);
      return { ...coupon, Applied };
    });

    const data: CouponsAppliedToFinalOrder = { fundraiserId, appliedCoupons };

    try {
      await applyFinalOrderCouponsMutation(data);
      toast(<GenericToast text={ t('dashboard.finalOrderPage.coupons.toast.success') } />);
    } catch (error) {
      toast.error(t('dashboard.finalOrderPage.coupons.toast.error'));
    }
  };

  if (isLoading) {
    return (
      <Step title={ t('dashboard.finalOrderPage.coupons.header') }>
        <Flex
          data-testid={ `${ testId }-loading` }
          sx={ {
            alignItems: 'center',
            justifyContent: 'center',
            p: [ '16px', '32px' ],
            width: '100%',
          } }
        >
          <Spinner variant="lce" />
        </Flex>
      </Step>
    );
  }

  if (isError) {
    toast.error(t('dashboard.finalOrderPage.coupons.toast.fetchError'));
    //return null; //TODO: ADD error state
  }

  return (
    <Step title={ t('dashboard.finalOrderPage.coupons.header') }>
      <Flex data-testid={ testId } sx={ { flexDirection: 'column', gap: [ '16px', '32px' ], p: [ '16px', '32px' ] } }>
        <Flex sx={ { flexDirection: 'column', gap: [ '8px', '16px' ] } }>
          <Text variant="dashboard.finalOrderPage.title">
            {t('dashboard.finalOrderPage.coupons.applyCoupons')}
          </Text>
          <Flex sx={ { flexDirection: 'column' } }>
            <Box sx={ { display: [ 'flex', 'grid' ], gridTemplateColumns: '1fr 1fr', gap: [ '8px', '16px' ] } }>
              <Form.Field
                component={ Form.Select }
                defaultValue=""
                disabled={ areAllCouponsApplied() }
                id="existingCoupons"
                label={ t('dashboard.finalOrderPage.coupons.existingCouponsLabel') }
                name="existingCoupons"
                onChange={ e => setSelectedCouponCode(e.target.value) }
                sx={ { my: '0px', width: '100%' } }
                type="text"
                value={ selectedCouponCode }
              >
                <Option label="" value="" />
                {_map(existingCoupons, coupon => (
                  <Option key={ coupon.Id } label={ coupon.Code } value={ coupon.Code } />
                ))}
              </Form.Field>
              <Button
                data-testId="add-selected-coupon-button"
                disabled={ isSelectedCouponApplied() || areAllCouponsApplied() || !selectedCouponCode }
                onClick={ handleAddCoupon }
                sx={ { alignSelf: 'end', width: 'fit-content' } }
                variant={ isSelectedCouponApplied() || areAllCouponsApplied() ? 'disabled' : 'primary' }
              >
                {t('dashboard.finalOrderPage.coupons.addCoupon')}
              </Button>
            </Box>
            { isSelectedCouponApplied() && (
              <Text variant="dashboard.finalOrderPage.error">
                {t('dashboard.finalOrderPage.coupons.selectedCouponApplied')}
              </Text>
            )}
            { areAllCouponsApplied() && (
              <Text variant="dashboard.finalOrderPage.error">
                {t('dashboard.finalOrderPage.coupons.allCouponsApplied')}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex sx={ { flexDirection: 'column', gap: [ '8px', '16px' ] } }>
          <Text variant="dashboard.finalOrderPage.title">
            {t('dashboard.finalOrderPage.coupons.couponsApplied')}
          </Text>
          {_map(appliedCouponCodes, coupon => (
            <Box sx={ { display: 'grid', gridTemplateColumns: [ 'auto 24px', '1fr 1fr' ], gap: [ '8px', '16px' ] } }>
              <Text variant="dashboard.finalOrderPage.body">
                {coupon}
              </Text>
              <Button
                data-testid={ `${ coupon }-remove-button` }
                onClick={ () => handleRemoveCoupon(coupon) }
                sx={ { height: '24px' } }
                variant="plainText"
              >
                <XCircle size={ 24 } />
              </Button>
            </Box>
          ))}
        </Flex>
        <Button data-testId="submit-applied-coupons-button" onClick={ onSubmit } sx={ { width: '100%' } }>
          {t('dashboard.finalOrderPage.coupons.submitButton')}
        </Button>
      </Flex>
    </Step>
  );
};
