/* eslint-disable max-len */
import { Box, Text } from '@lce/slice_v2';
import React, { useState } from 'react';
import { useTranslation } from '@lce/i18n';

import { PopupBorder } from './PopupBorder';

import VideoModal from 'ui/VideoModal';

export const SellerInvitePopup: React.FC = () => {
  const [ isVideoOpen, setIsVideoOpen ] = useState<boolean>(false);
  const [ t ] = useTranslation();

  return (
    <>
      <PopupBorder>
        <Text sx={ { fontSize: '18px', lineHeight: 1.2, mb: 4 } }>
          {t('dashboard.steps.popoverContent.sellerInvite.watch')}
          {' '}
          <Box
            onClick={ () => setIsVideoOpen && setIsVideoOpen(true) }
            sx={ {
              color: 'primaryOrange', textDecoration: 'underline', cursor: 'pointer', display: 'inline-block',
            } }
          >
            {t('dashboard.steps.popoverContent.sellerInvite.video')}
          </Box>
          {' '}
          {t('dashboard.steps.popoverContent.sellerInvite.help')}
        </Text>
        <Text sx={ { fontSize: '13px', lineHeight: 1.2, fontWeight: 'normal' } }>
          {t('dashboard.steps.popoverContent.sellerInvite.note')}
        </Text>
      </PopupBorder>
      <VideoModal
        isOpen={ isVideoOpen }
        onClose={ () => setIsVideoOpen(false) }
        url="https://player.vimeo.com/video/567360169"
      />
    </>
  );
};
